<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size:16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size:16px">货源信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>货源信息</span></p>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>
    <!-- 货源信息 -->
    <div class="news">
      <!-- 货源信息列表 -->
      <div class="newscontent">
        <el-table @row-click="openDetails" :data="supplyList" v-loading="loading" style="width: 100%">
<!--          <el-table-column prop="SUPPLY_SN" label="货源单号" width="200">-->
<!--            <template slot-scope="scope">-->
<!--              <el-link @click="openDetails(scope.row)" type="primary" :underline="false" alt="点击查看详情">-->
<!--                {{ scope.row.SUPPLY_SN }}-->
<!--              </el-link>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="GOODS_NAME" label="货物类型" width="100"></el-table-column>
          <el-table-column prop="FHR_NAME" label="发货人" width="100"></el-table-column>
          <el-table-column prop="FHR_PHONE" label="联系电话" width="180"></el-table-column>
          <el-table-column prop="START_CITY" label="装货地址" width="250"></el-table-column>
          <el-table-column prop="END_CITY" label="卸货地址" width="250"></el-table-column>
        </el-table>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="pageNum"
            :limit.sync="pageSize"
            @pagination="getSupply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../components/pagination";
import axios from 'axios'
import {getMenuList} from "@/request";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      menu: {
        bannerImg: ''
      },
      loading: true,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      supplyList: []
    }
  },
  created() {
    getMenuList({path: this.$route.path}).then(res => {
      this.menu = res.data[0];
    });
    this.getSupply();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getSupply() {
      axios.get(window.apiUrl + "/api/common/supplyList?pageSize="+this.pageSize+"&pageNum="+this.pageNum).then(res => {
        this.total = res.data.total;
        this.supplyList = res.data.rows;
        this.loading = false;
      });
    },
    openDetails(row){
      this.$router.push({name: '货源详情', params: {id: row.SUPPLY_ID}});
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bews_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #efefef;
}

.line .linebg {
  height: 5px;
  width: 80px;
  background-color: black;
}

.http {
  width: 700px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  height: 30px;
  margin-bottom: 100px;
  line-height: 30px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  text-align: left;
  padding-bottom: 50px;
  text-justify: inter-word;
  background: url("../../static/切图文件/bg-map-1.png") no-repeat top center;
}

.news .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 30px;
  vertical-align: top;
}

.news .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.news .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}

.downbtn {
  margin-top: 30px;
  height: 40px;
  text-align: center;
  /* transform: translate(-52px); */
}

.jiazaimes {
  font-size: 18px;
  color: rgb(249, 145, 0);
}
</style>